(function($) {

    $.fn.tblrowappend = function( options ) {

        // Establish our default settings
        var settings = $.extend({

            indexstart      : 0,
            hideTemplateRow : true,

            autoIncrementFirstCol : true,
            autoIncrementFirstColText : '#',

            // callback function
            initComplete    : null,

            addRowBtnText   : '+',
            addRowBtnClass  : 'btn btn-info',
            // callback function
            addRowComplete  : null,

            delRowBtnText   : '-',
            delRowBtnClass  : 'btn btn-danger',
            // callback function
            delRowComplete  : null

        }, options);


        function processRow($table, $tr, $index) {

            var $cur_index = $index || $table.find('tbody > tr').length-1;

            $tr.show().
                removeClass('template').
                append('<td><a class="delrow '+settings.delRowBtnClass+'">'+settings.delRowBtnText+'</a></td>').
                find('td > :input[name*="replaceindex"]').each(function(){
                    var elename = $(this).attr('name').replace('replaceindex', $cur_index);

                    if(!$(this).attr('ori_disabled')){
                      $(this).removeAttr('disabled');
                    }
                    $(this).attr('name',elename);
                });

            // Bind onClick Delete row event to + button
            $tr.find('td:last-child > a.delrow').click(function(){
                var $del_tr = $(this).closest('tr');
                var $del_index = $table.find('tbody > tr').index($del_tr);

                // Instead of remove the row directly,
                // we just temporary disable the input so it wouldn't submit to server
                $del_tr.find(':input').prop('disabled', 'disabled');
                $del_tr.hide();

                // callback after delete row complete
                // passing variable for current table and deleted row
                if( $.isFunction( settings.delRowComplete ) ) {
                    settings.delRowComplete.call( this, $table, $del_tr, $del_index );
                }
            });


            if ( settings.autoIncrementFirstCol ){
               $tr.prepend('<th>'+$cur_index+'</th>');
            }

            // callback after add row complete
            // passing variable for current table and added row
            if( $.isFunction( settings.addRowComplete ) ) {
                settings.addRowComplete.call( this, $table, $tr, $index );
            }
        }

        return this.each( function() {

            var $table = $(this);

            if( settings.hideTemplateRow ) {
                $table.find('tr.template').hide();
            }

            // disable template input type so it wouldn't submit the value to server
            $table.find('tr.template :input').each(function(){
                var disabled = $table.attr('disabled');
                if(disabled){
                  $table.attr('ori_disabled',''+disabled).attr('disabled', 'disabled');
                }else{
                  $table.attr('disabled', 'disabled');
                }
            });

            // Add + button in thead
            $table.find('thead > tr').append('<th><a class="addrow '+settings.addRowBtnClass+'">'+settings.addRowBtnText+'</a></th>');

            var tr_index = 0 + parseInt(settings.indexstart);
            $table.find('tbody > tr').each(function(){
                var $tr = $(this);
                processRow($table, $tr, tr_index);

                tr_index++;
            });

            if ( settings.autoIncrementFirstCol ){
                $table.find('thead > tr:first-child').prepend('<th>'+settings.autoIncrementFirstColText+'</th>');
            }

            if( $.isFunction( settings.initComplete ) ) {
                settings.initComplete.call( this, $(this), $(this).find('thead > tr > th > a.addrow '));
            }

            // Bind onClick Add row event to + button
            $(this).find('thead th a.addrow').click(function(){
                var $table = $(this).closest('table');
                $table.find('tbody').append( $table.find('tr.template').clone());

                var $tr = $table.find('tbody > tr:last-child');
                var $index = parseInt($table.find('tbody > tr').index($tr)) + parseInt(settings.indexstart);
                processRow($table, $tr, $index);


            });
        });

    }

}(jQuery));