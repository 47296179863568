



$(function () {
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });
});



function wopen(url,name,w,h){
  if(typeof w == 'undefined'){ w = 900; }
  if(typeof h == 'undefined'){ h = screen.height; }
  if(typeof name == 'undefined'){ name = url; }
  if(w != 'full' && h != 'full'){
    w=parseInt(w)+32;h=parseInt(h)+96;
    wleft=(screen.width-w)/2;wtop=(screen.height-h)/2;
    if(wleft<0){w=screen.width;wleft=0;}
    if(wtop<0){h=screen.height;wtop=0;}
    var win=window.open(url,name,'width='+w+', height='+h+', '+'left='+
        wleft+', top='+wtop+', '+'location=no, menubar=no, '+
        'status=no, toolbar=no, scrollbars=yes, resizable=no');
    win.resizeTo(w,h);win.moveTo(wleft,wtop);win.focus();
  }else{
    var win=window.open(url,name,'width='+screen.width+', '+
        'height='+screen.height+', top=0, left=0,  fullscreen=yes, '+
        'location=no, menubar=no, status=no, toolbar=no,'+
        'scrollbars=yes, resizable=no');
    win.resizeTo(screen.width,screen.height);
    win.moveTo(0,0);win.focus();
  }
}

function popupUpdate(fn, results, jsReturnObj){

  // example fn = 'myfunction'
  // window[fn](results) = myfunction(results)
  if (jsReturnObj !== undefined) {
    window[fn](results,jsReturnObj);
  }else{
    window[fn](results);
  }
}

/*
 $( "#edit-supplier-code" ).autocomplete({minLength:2,autoFocus:true,source:function( request, response ) {
  // cancel previous autocomplete request
  if(typeof ac_xhr == "object"){
    ac_xhr.abort();
  }
  var term = request.term;
  if (typeof cache != "undefined" && term in cache ) {
    response( cache[ term ] );
    return;
  }
  ac_xhr = $.ajax({
    url: "/erp_core/ajax/json/erp_supplier/get_supplier_details_by_name_autocomplete",
    type: "post",
    data: {
      term: term,

    },
    success: function( data ) {
      if(typeof cache != "undefined"){
        cache[ term ] = data;
      }
      response( data );
    }
  });
},select:function( event, ui ) {
jQuery("input[name=supplier_id]").val(ui.item.data.supplier_id);
jQuery("input[name=supplier_name]").val(ui.item.data.supplier_name);
    jQuery("textarea[name=supplier_address]").val(ui.item.data.billing_address);
jQuery("input[name=attention]").val(ui.item.data.attention);
    if(ui.item.data.credit_term_id != null){
              jQuery("#edit-credit-term-id").val(ui.item.data.credit_term_id);
    }
    jQuery("input[name=delivery_term]").val(ui.item.data.delivery_term);
    jQuery("#edit-currency").val(ui.item.data.currency_id);
    if(ui.item.data.currency_id){
              //jQuery("#edit-currency").attr("disabled", true);
              jQuery("#edit-currency").css("background-color", "#C0C0C0");
              jQuery("input[name=currency_hidden]").val(ui.item.data.currency_id);
          }else{
              //jQuery("#edit-currency").attr("disabled", false);
              jQuery("#edit-currency").css("background-color", "#FFFFFF");
              jQuery("input[name=currency_hidden]").val("");
          }
}}).keypress(function(e) {
      var code = (e.keyCode ? e.keyCode : e.which);
      if(code == 13) { //Enter keycode
          return false;
      }
  });
});    */